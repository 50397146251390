import * as Types from '../../gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListDashboardCommandRatesQueryVariables = Types.Exact<{
  commandIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
  locale: Types.Locales;
}>;


export type ListDashboardCommandRatesQuery = { __typename?: 'Query', listDashboardCommandRates: { __typename?: 'DashboardCommandRatesListResponseObject', items?: Array<{ __typename?: 'DashboardCommandRatesObject', commandId: string, rates: Array<{ __typename?: 'DashboardCommandRateObject', rateId: string, quantity: number, title: string, seats?: number | null }> }> | null } };

export type ListDashboardCommandBidRatesQueryVariables = Types.Exact<{
  commandIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
  locale: Types.Locales;
}>;


export type ListDashboardCommandBidRatesQuery = { __typename?: 'Query', listDashboardCommandBidRates: { __typename?: 'BidRateObjectEfazSQkt', items?: Array<{ __typename?: 'BidRateObject', rateId: string, title: string }> | null } };

export type RetrieveCommandQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type RetrieveCommandQuery = { __typename?: 'Query', retrieveCommand: { __typename?: 'CommandObjectp4eFgbm9', object?: { __typename?: 'CommandObject', id: string, address?: string | null, city?: string | null, corporationName?: string | null, country?: Types.CountryCode | null, source: Types.CommandSource, discountAmount: number, discountId?: string | null, eligibleAmount: number, email?: string | null, firstName?: string | null, isCorporate: boolean, lastName?: string | null, paymentMethod?: Types.PaymentMethod | null, postalCode?: string | null, productsAmount: number, extraDonation: number, region?: string | null, tipAmount: number, tipPercentage: number, totalAmount: number, createdAtUtc: GraphQL_Date, eTicketUrl?: string | null, productTickets?: Array<{ __typename?: 'ProductTicketObject', id: string, annotation?: string | null, rateId: string, amount: number, commandId: string, currency: Types.AvailablePaymentCurrency, organizationId: string, status: Types.ProductTicketStatus, scanDates?: Array<GraphQL_Date> | null, ticketingIndex?: number | null }> | null, productDonation?: { __typename?: 'ProductDonationObject', id: string, inHonourName?: string | null, annotation?: string | null, amount: number, isAnonymous: boolean } | null, discount?: { __typename?: 'DiscountObject', id: string, amount: number, body: string } | null } | null, error?: { __typename?: 'CommandObjectp4eFgbm9ErrorObject', code?: string | null, message: string } | null } };

export type RetrieveDonorCommandQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type RetrieveDonorCommandQuery = { __typename?: 'Query', retrieveDonorCommand: { __typename?: 'CommandObjectjSzU7dnq', object?: { __typename?: 'CommandObject', id: string, address?: string | null, city?: string | null, corporationName?: string | null, country?: Types.CountryCode | null, discountAmount: number, discountId?: string | null, eligibleAmount: number, email?: string | null, firstName?: string | null, isCorporate: boolean, lastName?: string | null, paymentMethod?: Types.PaymentMethod | null, postalCode?: string | null, productsAmount: number, region?: string | null, tipAmount: number, tipPercentage: number, totalAmount: number, createdAtUtc: GraphQL_Date, eTicketUrl?: string | null, donationId?: string | null, productTickets?: Array<{ __typename?: 'ProductTicketObject', id: string, annotation?: string | null, rateId: string, amount: number, commandId: string, currency: Types.AvailablePaymentCurrency, organizationId: string, status: Types.ProductTicketStatus, scanDates?: Array<GraphQL_Date> | null, expirationDate?: GraphQL_Date | null, automaticRenewal?: boolean | null }> | null, productDonation?: { __typename?: 'ProductDonationObject', id: string, inHonourName?: string | null, annotation?: string | null, amount: number } | null, discount?: { __typename?: 'DiscountObject', id: string, amount: number, body: string } | null } | null, error?: { __typename?: 'CommandObjectjSzU7dnqErrorObject', code?: string | null, message: string } | null } };

export type EditCommandSubscriptionAmountMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  amount: Types.Scalars['Float'];
}>;


export type EditCommandSubscriptionAmountMutation = { __typename?: 'Mutation', editCommandSubscriptionAmount: { __typename?: 'CommandResponseObject', object?: { __typename?: 'CommandObject', id: string } | null, error?: { __typename?: 'CommandResponseObjectErrorObject', code?: string | null, message: string } | null } };

export type EditDonorCommandSubscriptionAmountMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  amount: Types.Scalars['Float'];
}>;


export type EditDonorCommandSubscriptionAmountMutation = { __typename?: 'Mutation', editDonorCommandSubscriptionAmount: { __typename?: 'CommandResponseObject', object?: { __typename?: 'CommandObject', id: string } | null, error?: { __typename?: 'CommandResponseObjectErrorObject', code?: string | null, message: string } | null } };

export type CancelCommandSubscriptionMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type CancelCommandSubscriptionMutation = { __typename?: 'Mutation', cancelCommandSubscription: { __typename?: 'CommandObjectat6T0N6Z', object?: { __typename?: 'CommandObject', id: string } | null, error?: { __typename?: 'CommandObjectat6T0N6ZErrorObject', code?: string | null, message: string } | null } };

export type CancelDonorCommandSubscriptionMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type CancelDonorCommandSubscriptionMutation = { __typename?: 'Mutation', cancelDonorCommandSubscription: { __typename?: 'CommandObjecthoilGCAs', object?: { __typename?: 'CommandObject', id: string } | null, error?: { __typename?: 'CommandObjecthoilGCAsErrorObject', code?: string | null, message: string } | null } };

export type EditDonationCommandFormMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  donationFormId: Types.Scalars['String'];
}>;


export type EditDonationCommandFormMutation = { __typename?: 'Mutation', editDonationCommandForm: { __typename?: 'CommandResponseObject', object?: { __typename?: 'CommandObject', id: string } | null, error?: { __typename?: 'CommandResponseObjectErrorObject', code?: string | null, message: string } | null } };

export type SaveDonationThankYouCommentMutationVariables = Types.Exact<{
  commandId: Types.Scalars['String'];
  thankYouComment: Types.Scalars['String'];
}>;


export type SaveDonationThankYouCommentMutation = { __typename?: 'Mutation', saveDonationThankYouComment: { __typename?: 'CommandResponseObject', object?: { __typename?: 'CommandObject', id: string } | null } };

export type ReOpenCommandMutationVariables = Types.Exact<{
  commandId: Types.Scalars['String'];
}>;


export type ReOpenCommandMutation = { __typename?: 'Mutation', reOpenCommand: { __typename?: 'BooleanVSQjYv7m', object?: boolean | null } };

export type PostSubmitCommandMutationVariables = Types.Exact<{
  commandId: Types.Scalars['String'];
}>;


export type PostSubmitCommandMutation = { __typename?: 'Mutation', postSubmitCommand: { __typename?: 'PostSubmitCommandObjectTVHxYZub', object?: { __typename?: 'PostSubmitCommandObject', createFundraisingHandler?: { __typename?: 'CreatedCampaignsObject', team?: { __typename?: 'FundraiserDonationFormObject', id: string, teamId?: string | null, color: string, logoUrl?: string | null, token: string, isFundraiserFormEditDisabled: boolean } | null, individualCampaigns?: Array<{ __typename?: 'FundraiserDonationFormObject', id: string, teamId?: string | null, color: string, logoUrl?: string | null, token: string, isFundraiserFormEditDisabled: boolean }> | null } | null } | null, error?: { __typename?: 'PostSubmitCommandObjectTVHxYZubErrorObject', code?: string | null, message: string } | null } };

export type ListCommandsFromByOccurrenceIdQueryVariables = Types.Exact<{
  occurrenceId: Types.Scalars['String'];
}>;


export type ListCommandsFromByOccurrenceIdQuery = { __typename?: 'Query', listCommandsFromByOccurrenceId: { __typename?: 'CommandObjectMPnbTT8O', items?: Array<{ __typename?: 'CommandObject', id: string, email?: string | null, firstName?: string | null, productBids?: Array<{ __typename?: 'ProductBidObject', id: string, status: Types.ProductBidStatus, rate?: { __typename?: 'RateObject', id: string, rateFields?: Array<{ __typename?: 'RateFieldObject', id: string, locale: Types.Locales, title: string }> | null } | null }> | null }> | null } };


export const ListDashboardCommandRatesDocument = gql`
    query listDashboardCommandRates($commandIds: [String!]!, $locale: Locales!) {
  listDashboardCommandRates(commandIds: $commandIds, locale: $locale) {
    items {
      commandId
      rates {
        rateId
        quantity
        title
        seats
      }
    }
  }
}
    `;

/**
 * __useListDashboardCommandRatesQuery__
 *
 * To run a query within a React component, call `useListDashboardCommandRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDashboardCommandRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDashboardCommandRatesQuery({
 *   variables: {
 *      commandIds: // value for 'commandIds'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useListDashboardCommandRatesQuery(baseOptions: Apollo.QueryHookOptions<ListDashboardCommandRatesQuery, ListDashboardCommandRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDashboardCommandRatesQuery, ListDashboardCommandRatesQueryVariables>(ListDashboardCommandRatesDocument, options);
      }
export function useListDashboardCommandRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDashboardCommandRatesQuery, ListDashboardCommandRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDashboardCommandRatesQuery, ListDashboardCommandRatesQueryVariables>(ListDashboardCommandRatesDocument, options);
        }
export type ListDashboardCommandRatesQueryHookResult = ReturnType<typeof useListDashboardCommandRatesQuery>;
export type ListDashboardCommandRatesLazyQueryHookResult = ReturnType<typeof useListDashboardCommandRatesLazyQuery>;
export type ListDashboardCommandRatesQueryResult = Apollo.QueryResult<ListDashboardCommandRatesQuery, ListDashboardCommandRatesQueryVariables>;
export const ListDashboardCommandBidRatesDocument = gql`
    query listDashboardCommandBidRates($commandIds: [String!]!, $locale: Locales!) {
  listDashboardCommandBidRates(commandIds: $commandIds, locale: $locale) {
    items {
      rateId
      title
    }
  }
}
    `;

/**
 * __useListDashboardCommandBidRatesQuery__
 *
 * To run a query within a React component, call `useListDashboardCommandBidRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDashboardCommandBidRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDashboardCommandBidRatesQuery({
 *   variables: {
 *      commandIds: // value for 'commandIds'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useListDashboardCommandBidRatesQuery(baseOptions: Apollo.QueryHookOptions<ListDashboardCommandBidRatesQuery, ListDashboardCommandBidRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDashboardCommandBidRatesQuery, ListDashboardCommandBidRatesQueryVariables>(ListDashboardCommandBidRatesDocument, options);
      }
export function useListDashboardCommandBidRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDashboardCommandBidRatesQuery, ListDashboardCommandBidRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDashboardCommandBidRatesQuery, ListDashboardCommandBidRatesQueryVariables>(ListDashboardCommandBidRatesDocument, options);
        }
export type ListDashboardCommandBidRatesQueryHookResult = ReturnType<typeof useListDashboardCommandBidRatesQuery>;
export type ListDashboardCommandBidRatesLazyQueryHookResult = ReturnType<typeof useListDashboardCommandBidRatesLazyQuery>;
export type ListDashboardCommandBidRatesQueryResult = Apollo.QueryResult<ListDashboardCommandBidRatesQuery, ListDashboardCommandBidRatesQueryVariables>;
export const RetrieveCommandDocument = gql`
    query retrieveCommand($id: String!) {
  retrieveCommand(id: $id) {
    object {
      id
      address
      city
      corporationName
      country
      source
      discountAmount
      discountId
      eligibleAmount
      email
      firstName
      isCorporate
      lastName
      paymentMethod
      postalCode
      productsAmount
      extraDonation
      region
      tipAmount
      tipPercentage
      totalAmount
      createdAtUtc
      eTicketUrl
      productTickets {
        id
        annotation
        rateId
        amount
        commandId
        currency
        organizationId
        status
        scanDates
        ticketingIndex
      }
      productDonation {
        id
        inHonourName
        annotation
        amount
        isAnonymous
      }
      discount {
        id
        amount
        body
      }
    }
    error {
      code
      message
    }
  }
}
    `;

/**
 * __useRetrieveCommandQuery__
 *
 * To run a query within a React component, call `useRetrieveCommandQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveCommandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveCommandQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRetrieveCommandQuery(baseOptions: Apollo.QueryHookOptions<RetrieveCommandQuery, RetrieveCommandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetrieveCommandQuery, RetrieveCommandQueryVariables>(RetrieveCommandDocument, options);
      }
export function useRetrieveCommandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetrieveCommandQuery, RetrieveCommandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetrieveCommandQuery, RetrieveCommandQueryVariables>(RetrieveCommandDocument, options);
        }
export type RetrieveCommandQueryHookResult = ReturnType<typeof useRetrieveCommandQuery>;
export type RetrieveCommandLazyQueryHookResult = ReturnType<typeof useRetrieveCommandLazyQuery>;
export type RetrieveCommandQueryResult = Apollo.QueryResult<RetrieveCommandQuery, RetrieveCommandQueryVariables>;
export const RetrieveDonorCommandDocument = gql`
    query retrieveDonorCommand($id: String!) {
  retrieveDonorCommand(id: $id) {
    object {
      id
      address
      city
      corporationName
      country
      discountAmount
      discountId
      eligibleAmount
      email
      firstName
      isCorporate
      lastName
      paymentMethod
      postalCode
      productsAmount
      region
      tipAmount
      tipPercentage
      totalAmount
      createdAtUtc
      eTicketUrl
      donationId
      productTickets {
        id
        annotation
        rateId
        amount
        commandId
        currency
        organizationId
        status
        scanDates
        expirationDate
        automaticRenewal
      }
      productDonation {
        id
        inHonourName
        annotation
        amount
      }
      discount {
        id
        amount
        body
      }
    }
    error {
      code
      message
    }
  }
}
    `;

/**
 * __useRetrieveDonorCommandQuery__
 *
 * To run a query within a React component, call `useRetrieveDonorCommandQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveDonorCommandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveDonorCommandQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRetrieveDonorCommandQuery(baseOptions: Apollo.QueryHookOptions<RetrieveDonorCommandQuery, RetrieveDonorCommandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetrieveDonorCommandQuery, RetrieveDonorCommandQueryVariables>(RetrieveDonorCommandDocument, options);
      }
export function useRetrieveDonorCommandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetrieveDonorCommandQuery, RetrieveDonorCommandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetrieveDonorCommandQuery, RetrieveDonorCommandQueryVariables>(RetrieveDonorCommandDocument, options);
        }
export type RetrieveDonorCommandQueryHookResult = ReturnType<typeof useRetrieveDonorCommandQuery>;
export type RetrieveDonorCommandLazyQueryHookResult = ReturnType<typeof useRetrieveDonorCommandLazyQuery>;
export type RetrieveDonorCommandQueryResult = Apollo.QueryResult<RetrieveDonorCommandQuery, RetrieveDonorCommandQueryVariables>;
export const EditCommandSubscriptionAmountDocument = gql`
    mutation editCommandSubscriptionAmount($id: String!, $amount: Float!) {
  editCommandSubscriptionAmount(id: $id, amount: $amount) {
    object {
      id
    }
    error {
      code
      message
    }
  }
}
    `;
export type EditCommandSubscriptionAmountMutationFn = Apollo.MutationFunction<EditCommandSubscriptionAmountMutation, EditCommandSubscriptionAmountMutationVariables>;

/**
 * __useEditCommandSubscriptionAmountMutation__
 *
 * To run a mutation, you first call `useEditCommandSubscriptionAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCommandSubscriptionAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCommandSubscriptionAmountMutation, { data, loading, error }] = useEditCommandSubscriptionAmountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useEditCommandSubscriptionAmountMutation(baseOptions?: Apollo.MutationHookOptions<EditCommandSubscriptionAmountMutation, EditCommandSubscriptionAmountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCommandSubscriptionAmountMutation, EditCommandSubscriptionAmountMutationVariables>(EditCommandSubscriptionAmountDocument, options);
      }
export type EditCommandSubscriptionAmountMutationHookResult = ReturnType<typeof useEditCommandSubscriptionAmountMutation>;
export type EditCommandSubscriptionAmountMutationResult = Apollo.MutationResult<EditCommandSubscriptionAmountMutation>;
export type EditCommandSubscriptionAmountMutationOptions = Apollo.BaseMutationOptions<EditCommandSubscriptionAmountMutation, EditCommandSubscriptionAmountMutationVariables>;
export const EditDonorCommandSubscriptionAmountDocument = gql`
    mutation editDonorCommandSubscriptionAmount($id: String!, $amount: Float!) {
  editDonorCommandSubscriptionAmount(id: $id, amount: $amount) {
    object {
      id
    }
    error {
      code
      message
    }
  }
}
    `;
export type EditDonorCommandSubscriptionAmountMutationFn = Apollo.MutationFunction<EditDonorCommandSubscriptionAmountMutation, EditDonorCommandSubscriptionAmountMutationVariables>;

/**
 * __useEditDonorCommandSubscriptionAmountMutation__
 *
 * To run a mutation, you first call `useEditDonorCommandSubscriptionAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDonorCommandSubscriptionAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDonorCommandSubscriptionAmountMutation, { data, loading, error }] = useEditDonorCommandSubscriptionAmountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useEditDonorCommandSubscriptionAmountMutation(baseOptions?: Apollo.MutationHookOptions<EditDonorCommandSubscriptionAmountMutation, EditDonorCommandSubscriptionAmountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDonorCommandSubscriptionAmountMutation, EditDonorCommandSubscriptionAmountMutationVariables>(EditDonorCommandSubscriptionAmountDocument, options);
      }
export type EditDonorCommandSubscriptionAmountMutationHookResult = ReturnType<typeof useEditDonorCommandSubscriptionAmountMutation>;
export type EditDonorCommandSubscriptionAmountMutationResult = Apollo.MutationResult<EditDonorCommandSubscriptionAmountMutation>;
export type EditDonorCommandSubscriptionAmountMutationOptions = Apollo.BaseMutationOptions<EditDonorCommandSubscriptionAmountMutation, EditDonorCommandSubscriptionAmountMutationVariables>;
export const CancelCommandSubscriptionDocument = gql`
    mutation cancelCommandSubscription($id: String!) {
  cancelCommandSubscription(id: $id) {
    object {
      id
    }
    error {
      code
      message
    }
  }
}
    `;
export type CancelCommandSubscriptionMutationFn = Apollo.MutationFunction<CancelCommandSubscriptionMutation, CancelCommandSubscriptionMutationVariables>;

/**
 * __useCancelCommandSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelCommandSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCommandSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCommandSubscriptionMutation, { data, loading, error }] = useCancelCommandSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelCommandSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelCommandSubscriptionMutation, CancelCommandSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelCommandSubscriptionMutation, CancelCommandSubscriptionMutationVariables>(CancelCommandSubscriptionDocument, options);
      }
export type CancelCommandSubscriptionMutationHookResult = ReturnType<typeof useCancelCommandSubscriptionMutation>;
export type CancelCommandSubscriptionMutationResult = Apollo.MutationResult<CancelCommandSubscriptionMutation>;
export type CancelCommandSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelCommandSubscriptionMutation, CancelCommandSubscriptionMutationVariables>;
export const CancelDonorCommandSubscriptionDocument = gql`
    mutation cancelDonorCommandSubscription($id: String!) {
  cancelDonorCommandSubscription(id: $id) {
    object {
      id
    }
    error {
      code
      message
    }
  }
}
    `;
export type CancelDonorCommandSubscriptionMutationFn = Apollo.MutationFunction<CancelDonorCommandSubscriptionMutation, CancelDonorCommandSubscriptionMutationVariables>;

/**
 * __useCancelDonorCommandSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelDonorCommandSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDonorCommandSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDonorCommandSubscriptionMutation, { data, loading, error }] = useCancelDonorCommandSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelDonorCommandSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelDonorCommandSubscriptionMutation, CancelDonorCommandSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelDonorCommandSubscriptionMutation, CancelDonorCommandSubscriptionMutationVariables>(CancelDonorCommandSubscriptionDocument, options);
      }
export type CancelDonorCommandSubscriptionMutationHookResult = ReturnType<typeof useCancelDonorCommandSubscriptionMutation>;
export type CancelDonorCommandSubscriptionMutationResult = Apollo.MutationResult<CancelDonorCommandSubscriptionMutation>;
export type CancelDonorCommandSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelDonorCommandSubscriptionMutation, CancelDonorCommandSubscriptionMutationVariables>;
export const EditDonationCommandFormDocument = gql`
    mutation editDonationCommandForm($id: String!, $donationFormId: String!) {
  editDonationCommandForm(id: $id, donationFormId: $donationFormId) {
    object {
      id
    }
    error {
      code
      message
    }
  }
}
    `;
export type EditDonationCommandFormMutationFn = Apollo.MutationFunction<EditDonationCommandFormMutation, EditDonationCommandFormMutationVariables>;

/**
 * __useEditDonationCommandFormMutation__
 *
 * To run a mutation, you first call `useEditDonationCommandFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDonationCommandFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDonationCommandFormMutation, { data, loading, error }] = useEditDonationCommandFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *      donationFormId: // value for 'donationFormId'
 *   },
 * });
 */
export function useEditDonationCommandFormMutation(baseOptions?: Apollo.MutationHookOptions<EditDonationCommandFormMutation, EditDonationCommandFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditDonationCommandFormMutation, EditDonationCommandFormMutationVariables>(EditDonationCommandFormDocument, options);
      }
export type EditDonationCommandFormMutationHookResult = ReturnType<typeof useEditDonationCommandFormMutation>;
export type EditDonationCommandFormMutationResult = Apollo.MutationResult<EditDonationCommandFormMutation>;
export type EditDonationCommandFormMutationOptions = Apollo.BaseMutationOptions<EditDonationCommandFormMutation, EditDonationCommandFormMutationVariables>;
export const SaveDonationThankYouCommentDocument = gql`
    mutation saveDonationThankYouComment($commandId: String!, $thankYouComment: String!) {
  saveDonationThankYouComment(
    commandId: $commandId
    thankYouComment: $thankYouComment
  ) {
    object {
      id
    }
  }
}
    `;
export type SaveDonationThankYouCommentMutationFn = Apollo.MutationFunction<SaveDonationThankYouCommentMutation, SaveDonationThankYouCommentMutationVariables>;

/**
 * __useSaveDonationThankYouCommentMutation__
 *
 * To run a mutation, you first call `useSaveDonationThankYouCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDonationThankYouCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDonationThankYouCommentMutation, { data, loading, error }] = useSaveDonationThankYouCommentMutation({
 *   variables: {
 *      commandId: // value for 'commandId'
 *      thankYouComment: // value for 'thankYouComment'
 *   },
 * });
 */
export function useSaveDonationThankYouCommentMutation(baseOptions?: Apollo.MutationHookOptions<SaveDonationThankYouCommentMutation, SaveDonationThankYouCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveDonationThankYouCommentMutation, SaveDonationThankYouCommentMutationVariables>(SaveDonationThankYouCommentDocument, options);
      }
export type SaveDonationThankYouCommentMutationHookResult = ReturnType<typeof useSaveDonationThankYouCommentMutation>;
export type SaveDonationThankYouCommentMutationResult = Apollo.MutationResult<SaveDonationThankYouCommentMutation>;
export type SaveDonationThankYouCommentMutationOptions = Apollo.BaseMutationOptions<SaveDonationThankYouCommentMutation, SaveDonationThankYouCommentMutationVariables>;
export const ReOpenCommandDocument = gql`
    mutation reOpenCommand($commandId: String!) {
  reOpenCommand(commandId: $commandId) {
    object
  }
}
    `;
export type ReOpenCommandMutationFn = Apollo.MutationFunction<ReOpenCommandMutation, ReOpenCommandMutationVariables>;

/**
 * __useReOpenCommandMutation__
 *
 * To run a mutation, you first call `useReOpenCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReOpenCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reOpenCommandMutation, { data, loading, error }] = useReOpenCommandMutation({
 *   variables: {
 *      commandId: // value for 'commandId'
 *   },
 * });
 */
export function useReOpenCommandMutation(baseOptions?: Apollo.MutationHookOptions<ReOpenCommandMutation, ReOpenCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReOpenCommandMutation, ReOpenCommandMutationVariables>(ReOpenCommandDocument, options);
      }
export type ReOpenCommandMutationHookResult = ReturnType<typeof useReOpenCommandMutation>;
export type ReOpenCommandMutationResult = Apollo.MutationResult<ReOpenCommandMutation>;
export type ReOpenCommandMutationOptions = Apollo.BaseMutationOptions<ReOpenCommandMutation, ReOpenCommandMutationVariables>;
export const PostSubmitCommandDocument = gql`
    mutation postSubmitCommand($commandId: String!) {
  postSubmitCommand(commandId: $commandId) {
    object {
      createFundraisingHandler {
        team {
          id
          teamId
          color
          logoUrl
          token
          isFundraiserFormEditDisabled
        }
        individualCampaigns {
          id
          teamId
          color
          logoUrl
          token
          isFundraiserFormEditDisabled
        }
      }
    }
    error {
      code
      message
    }
  }
}
    `;
export type PostSubmitCommandMutationFn = Apollo.MutationFunction<PostSubmitCommandMutation, PostSubmitCommandMutationVariables>;

/**
 * __usePostSubmitCommandMutation__
 *
 * To run a mutation, you first call `usePostSubmitCommandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostSubmitCommandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postSubmitCommandMutation, { data, loading, error }] = usePostSubmitCommandMutation({
 *   variables: {
 *      commandId: // value for 'commandId'
 *   },
 * });
 */
export function usePostSubmitCommandMutation(baseOptions?: Apollo.MutationHookOptions<PostSubmitCommandMutation, PostSubmitCommandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostSubmitCommandMutation, PostSubmitCommandMutationVariables>(PostSubmitCommandDocument, options);
      }
export type PostSubmitCommandMutationHookResult = ReturnType<typeof usePostSubmitCommandMutation>;
export type PostSubmitCommandMutationResult = Apollo.MutationResult<PostSubmitCommandMutation>;
export type PostSubmitCommandMutationOptions = Apollo.BaseMutationOptions<PostSubmitCommandMutation, PostSubmitCommandMutationVariables>;
export const ListCommandsFromByOccurrenceIdDocument = gql`
    query listCommandsFromByOccurrenceId($occurrenceId: String!) {
  listCommandsFromByOccurrenceId(occurrenceId: $occurrenceId) {
    items {
      id
      email
      firstName
      productBids {
        id
        status
        rate {
          id
          rateFields {
            id
            locale
            title
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListCommandsFromByOccurrenceIdQuery__
 *
 * To run a query within a React component, call `useListCommandsFromByOccurrenceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCommandsFromByOccurrenceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCommandsFromByOccurrenceIdQuery({
 *   variables: {
 *      occurrenceId: // value for 'occurrenceId'
 *   },
 * });
 */
export function useListCommandsFromByOccurrenceIdQuery(baseOptions: Apollo.QueryHookOptions<ListCommandsFromByOccurrenceIdQuery, ListCommandsFromByOccurrenceIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCommandsFromByOccurrenceIdQuery, ListCommandsFromByOccurrenceIdQueryVariables>(ListCommandsFromByOccurrenceIdDocument, options);
      }
export function useListCommandsFromByOccurrenceIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCommandsFromByOccurrenceIdQuery, ListCommandsFromByOccurrenceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCommandsFromByOccurrenceIdQuery, ListCommandsFromByOccurrenceIdQueryVariables>(ListCommandsFromByOccurrenceIdDocument, options);
        }
export type ListCommandsFromByOccurrenceIdQueryHookResult = ReturnType<typeof useListCommandsFromByOccurrenceIdQuery>;
export type ListCommandsFromByOccurrenceIdLazyQueryHookResult = ReturnType<typeof useListCommandsFromByOccurrenceIdLazyQuery>;
export type ListCommandsFromByOccurrenceIdQueryResult = Apollo.QueryResult<ListCommandsFromByOccurrenceIdQuery, ListCommandsFromByOccurrenceIdQueryVariables>;